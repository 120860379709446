
















































































import InfiniteScroll from "@monorepo/uikit/src/components/tableViews/InfiniteScroll.vue";
import Table from "@monorepo/uikit/src/components/common/Table.vue";
import SortPanelContainer from "@monorepo/uikit/src/components/common/SortPanelContainer.vue";
import TableHeader from "@monorepo/uikit/src/components/tableViews/TableHeader.vue";
import Filters from "@monorepo/uikit/src/components/common/Filters.vue";
import TableSettings from "@monorepo/uikit/src/components/tableViews/TableSettings.vue";
import HistoricalNoteInfoModal from "@monorepo/inventory/src/views/HistoricalNotesView/components/HistoricalNoteInfoModal.vue";
import ToggleTableCellsContainer from "@monorepo/inventory/src/views/HistoricalNotesView/components/ToggleTableCellsContainer.vue";
import Breadcrumbs from "@monorepo/uikit/src/components/common/Breadcrumbs.vue";
import ExportButton from "@monorepo/uikit/src/components/tableViews/ExportButton.vue";
import ScrollPanel from "@monorepo/uikit/src/components/tableViews/ScrollPanel.vue";
import { tableHeaders } from "@monorepo/utils/src/variables/projectsData/historicalNotesView/tableHeaders";
import { viewTitle, viewUniqKey } from "@monorepo/utils/src/variables/projectsData/historicalNotesView/viewTitle";
import { defineComponent, ref } from "@vue/composition-api";
import useSetStartFilters from "@monorepo/utils/src/composables/useSetStartFilters";
import { mapActions, mapGetters, Module } from "vuex";
import { breadcrumbs } from "@monorepo/inventory/src/views/HistoricalNotesView/constants/breadcrumbs";
import useInitTableStoreModule from "@monorepo/utils/src/store/composables/useInitTableStoreModule";
import { historicalNotesView } from "@monorepo/inventory/src/store/modules/historicalNotesView";
import useRefreshTable from "@monorepo/utils/src/composables/useRefreshTable";
import AutoRefreshButton from "@monorepo/uikit/src/components/tableViews/AutoRefreshButton.vue";
import useInitEducationPanel from "@monorepo/utils/src/composables/useInitEducationPanel";
import useSetStartCells from "@monorepo/utils/src/composables/useSetStartCells";
import useSetStartModal from "@monorepo/utils/src/composables/useSetStartModal";
import useOpenHistoryMode from "@monorepo/utils/src/composables/useOpenHistoryMode";
import ExportFooterButton from "@monorepo/uikit/src/components/tableViews/ExportFooterButton.vue";
import { convertApiItemToUi } from "@monorepo/inventory/src/views/HistoricalNotesView/utils/convertApiItemToUi";
import useMoveByAuthorities from "@monorepo/utils/src/composables/useMoveByAuthorities";
import { filtersElements } from "@monorepo/utils/src/variables/projectsData/historicalNotesView/filtersElements";
import { currentFilters as setCurrentFilters } from "@monorepo/inventory/src/views/HistoricalNotesView/utils/defaultCurrentFilters";
import AddElement from "@monorepo/uikit/src/components/tableViews/AddElement.vue";
import { fields } from "@monorepo/utils/src/variables/projectsData/historicalNotesView/fields";
import useGetTableLibs from "@monorepo/inventory/src/views/HistoricalNotesView/composables/useGetTableList";
import { convertToSelectItems } from "@monorepo/utils/src/utils/convertToSelectItems";
import Footer from "@monorepo/uikit/src/components/common/Footer.vue";
import useAsyncExport from "@monorepo/utils/src/composables/useAsyncExport";

const SECTION_NAME = "historicalNotesView";

export default defineComponent({
  name: "HistoricalNotesView",
  components: {
    TableSettings,
    Table,
    Filters,
    InfiniteScroll,
    ScrollPanel,
    ExportButton,
    TableHeader,
    Breadcrumbs,
    SortPanelContainer,
    HistoricalNoteInfoModal,
    ToggleTableCellsContainer,
    AutoRefreshButton,
    ExportFooterButton,
    AddElement,
    Footer,
  },
  data() {
    return {
      breadcrumbs,
      viewTitle,
      viewUniqKey,
      tableHeaders,
      pluralFormTitles: ["Историческая справка", "Исторических справки", "Исторических справок"],
      convertApiItemToUi,
      filtersElements,
      setCurrentFilters,
      autocompleteFiltersKeys: [fields.FUND_CODE],
    };
  },
  computed: {
    ...mapGetters("auth", ["isOpenEducation", "user"]),
    ...mapGetters(SECTION_NAME, [
      "cells",
      "isOpenFilters",
      "data",
      "openedId",
      "isSelectAll",
      "infiniteId",
      "totalLength",
      "selectedIds",
      "isTableLoading",
      "autorefresh",
      "isLoadingChangeAutorefresh",
      "kind",
      "approvedState",
    ]),
    isShowModal(): boolean {
      return !this.isOpenEducation && Boolean(this.openedId);
    },
    isSelectAllElement: {
      get(): boolean {
        return this.isSelectAll;
      },
      set(value: boolean) {
        this.$store.dispatch(`${SECTION_NAME}/setIsSelectAll`, value);
      },
    },
    selectItemsObj() {
      return {
        [fields.KIND]: convertToSelectItems((this.kind as string[]) ?? []),
        [fields.APPROVED_STATE]: convertToSelectItems(["Согласовано", "Не согласовано", "На согласовании"]),
      };
    },
    isShowFooter(): boolean {
      return this.isSelectAllElement || !!Object.values(this.selectedIds).filter(Boolean).length;
    },
  },
  methods: {
    ...mapActions(SECTION_NAME, ["getEventList", "getExportData", "addOpenedId", "setIsOpenFilters", "setAutorefresh"]),
    ...mapActions(SECTION_NAME, { setCellsNotes: "setCells" }),
    openModalIfNeed() {
      if (this.$route.query?.isOpenModal && this.data.length) {
        if (this.$route.query?.id) {
          this.addOpenedId(this.$route.query?.id as string);
          this.showModal = true;
          return;
        }
        this.addOpenedId(this.data[0].id);
        this.showModal = true;
      } else if (this.$route.query?.card && this.data.length) {
        this.addOpenedId(this.$route.query?.card as string);
        this.showModal = true;
      }
    },
    openModal({ header, event }: { header: { value: string; isLink: boolean }; event: Record<string, unknown> }) {
      switch (header.value) {
        case "openModal":
          if (event.id) {
            this.addOpenedId(event.id);
            this.toggleModal(true);
          }
          break;
        default:
          break;
      }
    },
    toggleModal(value: boolean) {
      this.showModal = value;
      if (!value) {
        this.$store.dispatch(`${SECTION_NAME}/addOpenedId`, null);
      }
    },
    setCells(value: Record<string, unknown>[]) {
      this.setCellsNotes(value.map((item) => item.value));
    },
    async onClearFilters() {
      const query = {
        ...(this.$route.query ?? {}),
        guid: undefined,
      };

      await this.$router.replace({ query });
    },
  },
  setup(props, { root }) {
    const filtersLoaded = ref(false);
    const showModal = ref(false);
    const sectionName = ref(SECTION_NAME); // sectionName === store module name
    const moveByAuthorities = useMoveByAuthorities(root);

    useInitTableStoreModule(root, SECTION_NAME, historicalNotesView as Module<unknown, unknown>);
    useOpenHistoryMode(root, SECTION_NAME);
    useSetStartCells(root, SECTION_NAME, root.$store.getters[`${SECTION_NAME}/section`], tableHeaders, true).then(() => {
      useRefreshTable(root, SECTION_NAME);
    });

    if (root.$route.query?.guid) {
      root.$store.commit(`${sectionName.value}/clearFilters`, { emptyFieldFilters: true });
    }

    useSetStartFilters(root, SECTION_NAME, ["id", "guid"]).then(() => {
      useInitEducationPanel(root);
      filtersLoaded.value = true;
      useSetStartModal(root, SECTION_NAME);
      if (root.$store.getters[`${SECTION_NAME}/openedId`]) {
        showModal.value = true;
      }
    });
    useGetTableLibs(root);
    useAsyncExport(root, SECTION_NAME);

    return {
      filtersLoaded,
      showModal,
      sectionName,
      moveByAuthorities,
    };
  },
});
